.titulo_certificado{
    text-align: center;
    padding:10px;
    margin-bottom: 50px;
    text-transform:uppercase;
   
}

.titulo_certificado  p{
    margin:0;
    padding:0;
}

@media (min-width: 1024px){
    .titulo_certificado{
        font-size:30px;
    }
}